
export const normalizeCurrencyCode = (currency: string) => {

    const currencyMap: { [key: string]: string | string[] } = {
        'Rs': 'INR',
        'Af': 'AFN',
        '$': 'USD',
        'Lek': 'ALL',
        'Arg$': 'ARS',
        'AU$': 'AUD',
        'ман': 'AZN',
        'Tk': 'BDT',
        'R$': 'BRL',
        'CA$': 'CAD',
        'CHF': 'CHF',
        'COL$': 'COP',
        'kr': ['DKK', 'NOK', 'SEK'],
        '€': 'EUR',
        '¥': ['CNY', 'JPY'],
        'Ksh': 'KES',
        '₩': 'KRW',
        'Mex$': 'MXN',
        'NZ$': 'NZD',
        '₽': 'RUB',
        '﷼': ['SAR', 'AED', 'IRR', 'OMR', 'YER'],
        'SGD': 'SGD',
        '₺': 'TRY',
        'R': 'ZAR',
        'د.إ': 'AED',
        'BD': 'BHD',
        'IQD': 'IQD',
        '₪': 'ILS',
        'JD': 'JOD',
        'KWD': 'KWD',
        'QR': 'QAR',
        '£': ['GBP', 'LBP', 'SYP'],
    };

    const value = currencyMap[currency] || currency.toUpperCase();
    return Array.isArray(value) ? value[0] : value;
};
