import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  Box,
  TextField,
  InputLabel,
  InputAdornment,
  Chip,
  Select,
  MenuItem
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { TypographyView, styles } from './styledFont';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { BreadCrumbs } from '../../../components/src/BreadCrumbs.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
import { arrowDown, calendar, imgPendingClockGrey } from './assets';
import { Autocomplete } from '@material-ui/lab';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import arabicLocale from 'react-date-object/locales/gregorian_ar';

import Ordermanagement1Controller, { Props, initBookingFormValues } from './Ordermanagement1Controller';
import { RegistrationFormView } from './regView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { initCatalogues } from "../../settings1/src/Settings1Controller";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from 'moment';
import { getTranslationConfig } from '../../../components/src/helpers';
import { arDays } from '../../../components/src/consts';

const ButtonComponent = styled(Box)({
  display: "flex",
  gridGap: '32px',
  flexDirection: "row",
  alignItems: "center !important",
  justifyContent: 'space-between',
  padding: "0 0 30px",
  '& .regButton': {
    display: 'flex',
    width: '150px',
    height: '65px',
    borderRadius: '8px',
    fontSize: '16px',
    background: 'var(--Primary, #398378)',
    fontFamily: 'Ubuntu',
    textTransform: 'none',
    textAlign: 'center',
    padding: 0
  }
});

// Customizable Area End
export default class OrdermanagementForm1 extends Ordermanagement1Controller {
  calendarSlotRef: React.RefObject<{
    openCalendar: () => void;
    closeCalendar: () => void;
  }> = React.createRef();
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.getCatalogues();
    // Customizable Area End
  }

  // Customizable Area Start
  navigationMap = {
    'Home': 'LandingPage',
    'Spa Profile': 'SpaUserProfile',
    'My Booking': 'SpaBooking',
  }
  // Customizable Area End

  render() {
    const { t, dir, isArabic } = getTranslationConfig();

    const genders = [
      t['profile-input-gender-male'],
      t['profile-input-gender-female']
    ];

    return (
      // Customizable Area Start
      <TypographyView>
        <Box style={styles.SafeAreaView} className='landing-venue-block'>
          <AppHeader
            context={this}
            forceUpdate={this.forceUpdate.bind(this)}
          />
          <Box
            dir={dir}
            py={6}
            px='140px'
          >
            {/* Heading */}
            <BreadCrumbs
              items={[t['breadcrumbs-home'], t['breadcrumbs-spa-profile'], t['breadcrumbs-my-bookings'], t['breadcrumbs-add-booking']]}
              targetColor='#398378'
              navigationMap={this.navigationMap}
              navigate={this.handleNavigate}
            />
            <Typography
              style={{
                fontFamily: 'Ubuntu',
                fontSize: '32px',
                fontWeight: 700,
                padding: '16px 0',
              }}
            >{t['booking-form-title']}</Typography>
            <Formik
              initialValues={initBookingFormValues}
              validationSchema={Yup.object().shape({
                name: Yup.string().required(t['booking-form-error-empty-name']),
                phone: Yup.number().typeError(t['booking-form-error-phone-validation']).required(t['booking-form-error-empty-phone']),
                gender: Yup.string().required(t['booking-form-error-empty-gender']),
                email: Yup.string().required(t['booking-form-error-empty-email']).email(t['booking-form-error-email-validation']),
                serviceName: Yup.object().shape({
                  name: Yup.string().required(t['booking-form-error-empty-service']),
                }),
                serviceCategory: Yup.object().shape({
                  name: Yup.string().required(t['booking-form-error-empty-category']),
                }),
                serviceSubCategory: Yup.object().shape({
                  name: Yup.string().required(t['booking-form-error-empty-sub-category']),
                }),
                date: Yup.string().required(t['booking-form-error-empty-date']),
                duration: Yup.object().shape({
                  duration: Yup.string().required(t['booking-form-error-empty-duration']),
                }),
                slots: Yup.string().required(t['booking-form-error-empty-slots']),
              })}
              onSubmit={async (values) => {
                const {
                  name,
                  phone,
                  email,
                  gender,
                  serviceName,
                  duration,
                  slots,
                  notes
                } = values;
                const date = this.state.bookingDate;
                const [start_time, end_time] = slots.split(" - ");

                const userId = await getStorageData("user_id");

                const data = {
                  start_time: moment(`${date} ${start_time}`).format('YYYY-MM-DD HH:mm:ss'),
                  end_time: moment(`${date} ${end_time}`).format('YYYY-MM-DD HH:mm:ss'),
                  booking_date: date,
                  service_provider_id: userId,
                  timezone: "UTC",
                  catalogue_id: serviceName.id,
                  bx_block_categories_slot_id: duration.id,
                  full_name: name,
                  phone_number: phone,
                  country_code: 91,
                  additional_notes: notes,
                  email,
                  gender,
                };

                await this.createBooking(data);
              }}
            >
              {({
                handleChange,
                submitForm,
                errors,
                touched,
                handleBlur,
                setFieldValue,
                values
              }) => {
                const {
                  name,
                  phone,
                  gender,
                  email,
                  serviceName,
                  serviceCategory,
                  serviceSubCategory,
                  date,
                  duration,
                  slots,
                } = errors;
                const isNameError = this.getInputError(touched.name, name);
                const isPhoneError = this.getInputError(touched.phone, phone);
                const isGenderError = this.getInputError(touched.gender, gender);
                const isEmailError = this.getInputError(touched.email, email);
                const isServiceNameError = this.getInputError(!!touched.serviceName, serviceName?.name);
                const isServiceCategoryError = this.getInputError(!!touched.serviceCategory, serviceCategory?.name);
                const isServiceSubCategoryError = this.getInputError(!!touched.serviceSubCategory, serviceSubCategory?.name);
                const isDurationError = this.getInputError(!!touched.duration, duration?.duration);

                return (
                  <RegistrationFormView style={{ borderRadius: '8px' }}>
                    <Box className="mainView" style={{ backgroundColor: '#FFFFFF', borderBottom: 'none', borderRadius: '8px' }}>
                      <Typography className="title">{t['booking-form-customer-info-title']}</Typography>
                      <Box >
                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                          <InputLabel className="testLabel">{t['booking-form-name']}</InputLabel>
                          <InputLabel className="testStar">*</InputLabel>
                        </Box>
                        <TextField
                          name='name'
                          fullWidth={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          className="inputeStyle"
                          data-test-id="customer-name"
                          placeholder={t['booking-form-name-placeholder']}
                          error={isNameError}
                          InputProps={{ style: { borderRadius: '8px' } }}
                        />
                        <Typography className="txtErrorMsg">{this.getErrorText(touched.name, name)}</Typography>
                      </Box>
                      <Box >
                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                          <InputLabel className="testLabel">{t['booking-form-phone']}</InputLabel>
                          <InputLabel className="testStar">*</InputLabel>
                        </Box>
                        <TextField
                          name='phone'
                          fullWidth={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          type="tel"
                          className="inputeStyle"
                          data-test-id="txtspaSummary"
                          placeholder={t['booking-form-phone-placehoder']}
                          error={isPhoneError}
                          size='medium'
                          {...isArabic && {
                            inputProps: { style: { textAlign: 'right' } }
                          }}
                        />
                        <Typography className="txtErrorMsg">{this.getErrorText(touched.phone, phone)}</Typography>
                      </Box>
                      <Box >
                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                          <InputLabel className="testLabel">{t['booking-form-email']}</InputLabel>
                          <InputLabel className="testStar">*</InputLabel>
                        </Box>
                        <TextField
                          name='email'
                          fullWidth={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          className="inputeStyle"
                          data-test-id="txtspaSummary"
                          placeholder={t['booking-form-email-placeholder']}
                          error={isEmailError}
                          multiline={true}
                          size='medium'
                          InputProps={{ style: { borderRadius: '8px' } }}
                        />
                        <Typography className="txtErrorMsg">{this.getErrorText(touched.email, email)}</Typography>
                      </Box>
                      <Box>
                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                          <InputLabel className="testLabel">{t['booking-form-gender']}</InputLabel>
                          <InputLabel className="testStar">*</InputLabel>
                        </Box>
                        <Select
                          name='gender'
                          data-test-id="gender-select"
                          fullWidth
                          variant="outlined"
                          value={values.gender}
                          className="input-booking-select"
                          displayEmpty
                          error={isGenderError}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          renderValue={this.renderInitSelect(values.gender, t['booking-form-gender-placeholder'])}
                          IconComponent={ExpandMoreIcon}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {genders.map((gender) => <MenuItem key={gender} value={gender}>{gender}</MenuItem>)}
                        </Select>
                        <Typography className="txtErrorMsg">{this.getErrorText(touched.gender, gender)}</Typography>
                      </Box>
                      <Box >
                        {/* Booking Details */}
                        <Box mt={4}>
                          <Typography className="title">{t['booking-form-booking-details-title']}</Typography>
                          <Box>
                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                              <InputLabel className="testLabel">{t['booking-form-category']}</InputLabel>
                              <InputLabel className="testStar">*</InputLabel>
                            </Box>
                            <Autocomplete
                              data-test-id="service-category-input"
                              fullWidth
                              options={this.getSimpleCategories()}
                              getOptionLabel={(option) => option.name}
                              className='inputeStyle'
                              autoComplete
                              autoHighlight
                              disableClearable
                              disablePortal
                              onChange={(_, newValue) => {
                                setFieldValue("serviceSubCategory", {
                                  id: "0",
                                  name: "",
                                });
                                setFieldValue("serviceName", {
                                  id: "0",
                                  name: "",
                                });
                                setFieldValue("date", "");
                                setFieldValue("duration", {
                                  id: "0",
                                  duration: "",
                                });
                                setFieldValue("slots", "");
                                this.setSelectedSlot("");
                                this.setTimeSlots([])
                                setFieldValue("serviceCategory", newValue);
                              }}
                              onBlur={handleBlur('serviceCategory')}
                              popupIcon={<img width={25} height={25} src={arrowDown} alt="" />}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  placeholder={t['booking-form-category-placeholder']}
                                  error={isServiceCategoryError}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    style: this.getAutocompleteStyle(isServiceCategoryError)
                                  }}
                                />
                              )}
                            />
                            <Typography className="txtErrorMsg">{this.getErrorText(touched.serviceCategory, serviceCategory?.name)}</Typography>
                          </Box>
                          <Box>
                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                              <InputLabel className="testLabel">{t['booking-form-sub-category']}</InputLabel>
                              <InputLabel className="testStar">*</InputLabel>
                            </Box>
                            <Autocomplete
                              data-test-id="service-subcategory-input"
                              fullWidth
                              disabled={!values.serviceCategory?.name}
                              options={this.getSubCategories(
                                values.serviceCategory?.id
                              )}
                              value={values.serviceSubCategory}
                              getOptionLabel={(option) => option.name}
                              className='inputeStyle'
                              autoComplete
                              autoHighlight
                              disableClearable
                              disablePortal
                              onChange={async (_, newValue) => {
                                setFieldValue("serviceSubCategory", newValue);
                                setFieldValue("serviceName", {
                                  id: "0",
                                  name: "",
                                });
                                setFieldValue("date", "");
                                setFieldValue("duration", {
                                  id: "0",
                                  duration: "",
                                });
                                setFieldValue("slots", "");
                                this.setSelectedSlot("");
                                this.setTimeSlots([])
                                await this.getServices(
                                  values.serviceCategory.id,
                                  newValue.id
                                );
                              }}
                              onBlur={handleBlur('serviceSubCategory')}
                              popupIcon={<img width={25} height={25} src={arrowDown} alt="" />}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  placeholder={t['booking-form-sub-category-placeholder']}
                                  error={isServiceSubCategoryError}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    style: this.getAutocompleteStyle(isServiceSubCategoryError)
                                  }}
                                />
                              )}
                            />
                            <Typography className="txtErrorMsg">{this.getErrorText(touched.serviceSubCategory, serviceSubCategory?.name)}</Typography>
                          </Box>
                          <Box>
                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                              <InputLabel className="testLabel">{t['booking-form-service-name']}</InputLabel>
                              <InputLabel className="testStar">*</InputLabel>
                            </Box>
                            <Autocomplete
                              data-test-id="service-name-input"
                              fullWidth
                              options={this.state.servicesOptions}
                              value={values.serviceName}
                              disabled={!values.serviceSubCategory?.name}
                              getOptionLabel={(option) => option.name}
                              className='inputeStyle'
                              autoComplete
                              autoHighlight
                              disableClearable
                              disablePortal
                              loading={this.state.servicesLoading}
                              onChange={(_, newValue) => {
                                setFieldValue("serviceName", newValue);
                                setFieldValue("date", "");
                                setFieldValue("duration", {
                                  id: "0",
                                  duration: "",
                                });
                                setFieldValue("slots", "");
                                this.setSelectedSlot("");
                                this.setTimeSlots([])

                                const firstAvailability =
                                  ((this.setSelectedService(newValue.id) || initCatalogues[0]).attributes.availabilities as any).find(
                                    (availability: any) => availability.duration
                                  );

                                const initAttribs =
                                  initCatalogues[0].attributes;
                                const { dates } =
                                  firstAvailability ||
                                  initAttribs.availabilities[0];

                                this.setSlotDays(dates);
                              }}
                              onBlur={handleBlur('serviceName')}
                              popupIcon={
                                <img
                                  width={25}
                                  height={25}
                                  src={arrowDown}
                                  alt=""

                                />}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  placeholder={t['booking-form-service-name-placeholder']}
                                  error={isServiceNameError}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    style:
                                      this.getAutocompleteStyle(
                                        isServiceNameError
                                      ),
                                  }}
                                />
                              )}
                            />
                            <Typography className="txtErrorMsg">
                              {this.getErrorText(touched.serviceName, serviceName?.name)}
                            </Typography>
                          </Box>
                          <Box display='flex' gridGap={8}>
                            {/* DatePicker */}
                            <Box width='100%'>
                              <Box
                                sx={{
                                  display: "flex",
                                  paddingTop: "15px",
                                  flexDirection: "row",
                                }}
                              >
                                <InputLabel className="testLabel">{t['booking-form-date']}</InputLabel>
                                <InputLabel className="testStar">*</InputLabel>
                              </Box>
                              <Box position='relative'>
                                <img
                                  src={calendar}
                                  alt="calendar"
                                  style={{
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    height: 24,
                                    width: 24,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    marginLeft: 8,
                                    ...(isArabic && {
                                      paddingRight: '8px',
                                    })
                                  }}
                                  data-test-id="date-picker-toggle"
                                  onClick={
                                    this.calendarSlotRef.current?.openCalendar
                                  }
                                />
                                <DatePicker
                                  {...(isArabic && { locale: arabicLocale })}
                                  className="basic-page-picker"
                                  ref={this.calendarSlotRef}
                                  disabled={!values.serviceName.name}
                                  placeholder={t['booking-form-date-placeholder']}
                                  inputClass="input-date-style"
                                  format="YYYY-MM-DD"
                                  monthYearSeparator=" "
                                  value={values.date}
                                  weekStartDayIndex={1}
                                  fixMainPosition
                                  calendarPosition="bottom-end"
                                  offsetX={120}
                                  mapDays={({ date: dateItem }) => {
                                    const enDay = arDays[dateItem.format("ddd")];
                                    if (!this.state.slotDays.includes(enDay)) {
                                      return {
                                        disabled: true,
                                        style: { color: "#ccc" },
                                      };
                                    }
                                  }}
                                  onChange={
                                    (dateItem: DateObject) => {
                                      const {
                                        year,
                                        month: { number },
                                        day
                                      } = dateItem
                                      // It's working for all international locales
                                      const dateString = [year, number, day].join('-');
                                      this.setBookingDate(dateString);

                                      setFieldValue(
                                        "date",
                                        dateItem.format("YYYY-MM-DD")
                                      );
                                      setFieldValue("duration", {
                                        id: "0",
                                        duration: "",

                                      });
                                      setFieldValue("slots", "");
                                      this.setSelectedSlot("");
                                      this.setTimeSlots([])
                                    }
                                  }
                                />
                              </Box>
                              <Typography className="txtErrorMsg">{this.getErrorText(touched.date, date)}</Typography>
                            </Box>
                            {/* Duration */}
                            <Box width='100%'>
                              <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                <InputLabel className="testLabel">{t['booking-form-duration']}</InputLabel>
                                <InputLabel className="testStar">*</InputLabel>
                              </Box>
                              <Autocomplete
                                data-test-id="duration-input"
                                fullWidth
                                options={
                                  ((this.state.selectedService || initCatalogues[0]) as any).attributes.availabilities.map(
                                    (availability: any) => {
                                      const { id, duration } = availability.duration;
                                      return { id, duration }
                                    })
                                }
                                getOptionLabel={(option) => option?.duration || ""}
                                value={values.duration}
                                disabled={!values.date}
                                className='inputeStyle'
                                autoComplete
                                autoHighlight
                                disableClearable
                                disablePortal
                                onChange={(_, newValue) => {
                                  const targetTimeSlots = this.state.selectedService?.attributes.availabilities.find(
                                    (avail) => avail.duration.id === newValue.id
                                  )?.timeslots;
                                  this.generateTimeSlots(newValue.duration, targetTimeSlots);
                                  setFieldValue("duration", {
                                    id: newValue.id,
                                    duration: newValue.duration,
                                  });
                                  // Reset selected slots
                                  setFieldValue("slots", "");
                                  this.setSelectedSlot("");
                                }}
                                onBlur={handleBlur('duration')} popupIcon={<img width={25} height={25} src={arrowDown} alt="" />}
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    placeholder={t['booking-form-duration-placeholder']}
                                    error={isDurationError}
                                    InputProps={{
                                      ...params.InputProps,
                                      disableUnderline: true,
                                      style: this.getAutocompleteStyle(isDurationError),
                                      startAdornment: (
                                        <InputAdornment
                                          position="end"
                                          {...(isArabic && {
                                            style: {
                                              position: 'absolute',
                                              left: 0
                                            }
                                          })}
                                        >
                                          <img width={20} height={20} src={imgPendingClockGrey} alt="pending" />
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                )}
                              />
                              <Typography className="txtErrorMsg">{this.getErrorText(touched.duration, duration?.duration)}</Typography>
                            </Box>
                          </Box>
                          {/* Time slots */}
                          <Box>
                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                              <InputLabel className="testLabel">{t['booking-form-slots']}</InputLabel>
                              <InputLabel className="testStar">*</InputLabel>
                            </Box>
                            <Box display='flex' gridGap={16} flexWrap='wrap'>
                              {this.state.timeSlots.map((slot, index) => (
                                <Chip
                                  data-test-id={`chip-${index}`}
                                  className="chip-item"
                                  label={slot}
                                  variant="outlined"
                                  key={slot}
                                  style={{
                                    height: '50px',
                                    width: '15%',
                                    borderRadius: '8px',
                                    border: '1px solid #398378',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    ...this.getHighlightSlotStyle(slot)
                                  }}
                                  onClick={() => {
                                    setFieldValue("slots", slot);
                                    this.setSelectedSlot(slot);
                                  }}
                                />
                              ))}
                            </Box>
                            <Typography className="txtErrorMsg">{this.getErrorText(touched.slots, slots)}</Typography>
                          </Box>
                          <Box >
                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                              <InputLabel className="testLabel">{t['booking-form-additional-notes']}</InputLabel>
                            </Box>
                            <TextField
                              name='notes'
                              fullWidth={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              className="inputeStyle"
                              data-test-id="txtInputSpaName"
                              placeholder={t['booking-form-additional-notes-placeholder']}
                              multiline={true}
                              inputProps={{
                                style: {
                                  height: "100px",
                                },
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="locationViewStyle" width={'94%'}>
                      <ButtonComponent>
                        <Button
                          data-test-id='back-btn'
                          variant="contained"
                          color="primary"
                          onClick={() => this.gotoMyBooking()}
                          className="regButton"
                          style={{
                            background: 'var(--Primary, #D9EEEB)',
                            color: '#398378',
                          }}
                        >
                          {t['booking-form-cancel-btn']}
                        </Button>
                        <Button
                          data-test-id='submit-btn'
                          variant="contained"
                          color="primary"
                          onClick={submitForm}
                          className="regButton"
                        >
                          {t['booking-form-add-booking-btn']}
                        </Button>
                      </ButtonComponent>
                    </Box>
                  </RegistrationFormView>
                )
              }}
            </Formik>
          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
