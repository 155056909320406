import React from "react";

// Customizable Area Start
import { Autocomplete } from '@material-ui/lab';
import { styled } from "@material-ui/core";

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiTextField-root': {
    borderRadius: "8px",
    border: `1px solid #CBD5E1`,
    '&:focus-within': {
      border: `2px solid #398378`,
    },
  },
}));
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";


export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 
  render() {
    return (
        <div>
            
        </div>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    // Customizable Area End
  }
}

