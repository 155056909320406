import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { getAndSaveCurrency, Languages, selectActiveSubscription, setSubscriptionStateToStorage } from '../../../components/src/helpers';
import { getFormDataFromPayload } from '../../../blocks/utilities/src/handle-form';

export const lang = Languages.en;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  emailError: boolean;
  pwdError: boolean;
  submitAttempted: boolean,
  open: boolean;
  apiMsg: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  goBack: string;
  param: string,
  profileStep: string | null,
  isPreviewMode: boolean,
  token: string | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  apiCurrentSubscriptionId = '';
  apiGetUserDetailsCallId = '';
  apiUpdateUserLanguageCallId = '';
  emailReg: RegExp;
  labelTitle: string = "";
  textHeading: string = "";
  formTiltle: string = "";
  txtEmail: string = "";
  txtPassword: string = "";
  description: string = "";
  star: string = "";
  emailErrorMsg = "";
  accountText: string = "";
  signUpTxt: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      email: "",
      emailError: false,
      pwdError: false,
      submitAttempted: false,
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      open: false,
      apiMsg: '',
      goBack: '',
      param: '',
      profileStep: null,
      isPreviewMode: false,
      token: null
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.textHeading = configJSON.textHeading;
    this.formTiltle = configJSON.formTiltle;
    this.txtEmail = configJSON.txtEmail;
    this.txtPassword = configJSON.txtPassword;
    this.description = configJSON.description;
    this.star = configJSON.star;
    this.emailErrorMsg = configJSON.emailErrorMsg;
    this.signUpTxt = configJSON.signUpTxt;
    this.accountText = configJSON.accountText;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const data = await getStorageData('loginDetail');
    let loginData = JSON.parse(data);
    this.setState({
      email: loginData?.email || null,
      password: loginData?.password || null,
      checkedRememberMe: loginData?.checkedRememberMe || null
    })
    // Customizable Area End
  }

  // Customizable Area Start

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handlePasswordChange = (e: any) => {
    this.setPassword(e.target.value);
    this.setState({
      pwdError: !e.target.value && this.state.submitAttempted,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text, emailError: false
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text, pwdError: false
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  handleClose = () => {
    this.setState({ open: false })
  }

  async updateUserLanguage(lang: string) {
    const headers = {
      'token': await getStorageData('authToken'),
    };

    const payload = {
      'account[profile][account_id]': await getStorageData('user_id'),
      'account[profile][language]': lang
    };

    const body = getFormDataFromPayload(payload);

    const updMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateUserLanguageCallId = updMessage.messageId;

    updMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfilePicEndPoint
    );

    updMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    updMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateProfileMethod
    );

    updMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    runEngine.sendMessage(updMessage.id, updMessage);
  }

  login = () => {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.setState({ emailError: true })
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.setState({ pwdError: true })
      return false;
    }
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.loginAPiEndPoint}?language=${lang}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  async getCurrentSubscription(token: string) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type': configJSON.loginApiContentType,
        'token': token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subscriptionApiMethod
    );
    this.apiCurrentSubscriptionId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getUserDetails() {
    const userID = await getStorageData('user_id');
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsAPIEndPoint}?id=${userID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.retrieveApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToSignUp = async () => {
    // Merge Engine - Navigation - btnSignUp - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigateEmailSignUpMessage), "email");
    this.send(msg);
    await setStorageData('userRole', 'Customer')
    // Merge Engine - Navigation - btnSignup - End
  }

  goToReviewPage = () => {
    const msg: Message = new Message(
      getName(this.state.goBack === 'Reviews' ? MessageEnum.NavigateReviewsMessage : MessageEnum.NavigateLandingPageVenue)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationPayLoadMessage), 'showReviewPrompt');
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.param);

    this.send(msg);
  }

  gotoSettings = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationSettings)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage), this.state.profileStep);
    this.send(message);
  }

  loginResponse = async (responseJson: any) => {
    if (responseJson && responseJson.meta) {
      this.setState({ profileStep: responseJson.meta.profile_step });

      if (responseJson.meta.token) {
        this.setState({ open: false })
        const languageStored= await getStorageData('language')

        await setStorageData("user_id", responseJson.meta.id);
        await setStorageData("userRole", responseJson.meta.user_role);
        await setStorageData("language", languageStored);

        this.saveLoggedInUserData(responseJson);
        this.sendLoginSuccessMessage();
        if (this.state.checkedRememberMe) {
          await setStorageData('loginDetail', JSON.stringify({ email: this.state.email, password: this.state.password, checkedRememberMe: this.state.checkedRememberMe }))
        } else {
          this.setState({ email: '', password: '', checkedRememberMe: false })
        }
        if (this.state.isPreviewMode) {
          window.location.href = '/BookingDetails/preview';
        } else {
          this.handleNavigationAsPerRole(responseJson);
        }
      }
    } else {
      //Check Error Response
      this.setState({ open: true, apiMsg: responseJson.errors[0].failed_login })
      this.sendLoginFailMessage();
    }


  }
  handleNavigationAsPerRole = async (responseJson: any) => {
    if (responseJson.meta.user_role === "Spa") {
      this.getUserDetails();
      this.setState({ token: responseJson.meta.token });
    } else {
      this.state.goBack ? this.goToReviewPage() : this.openInfoPage();
    }
  }

  openSpaOwnerNextPage() {
    Number(this.state.profileStep) < 6 ? this.gotoSettings() : this.openSpaOwnerProfilePage();
  }

  openSpaOwnerProfilePage() {
    const msg: Message = new Message(getName(MessageEnum.SpaProfileSuccessMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  openSubscriptionsPage() {
    const msg: Message = new Message(getName(MessageEnum.NavigationSubscriptionInvitation));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  assignRegexp(responseJson: any) {
    const arrayholder = responseJson.data;

    if (arrayholder.length) {
      let regexData = arrayholder[0];

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  goToLandingPage = () => {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const navigationPayloadMessage = getName(MessageEnum.NavigationPayLoadMessage);
    const navigationData = message.getData(getName(MessageEnum.NavigationPayLoadMessage));

    if (navigationData?.isPreviewMode) {
      this.setState({ isPreviewMode: navigationData.isPreviewMode });
    }

    if (message.id === navigationPayloadMessage) {
      const payloadMessage = message.getData(navigationPayloadMessage);
      const goBack = payloadMessage?.goBack;
      const param = payloadMessage?.param;

      this.setState({ goBack, param });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.validationApiCallId) {
        this.assignRegexp(responseJson);
      }
      if (apiRequestCallId === this.apiEmailLoginCallId) {
        const lang = await getStorageData('language');

        this.parseApiCatchErrorResponse(errorReponse);

        lang && await this.updateUserLanguage(lang);
        this.loginResponse(responseJson);
      }
      if (apiRequestCallId === this.apiCurrentSubscriptionId) {
        selectActiveSubscription(responseJson) ? this.openSpaOwnerNextPage() : this.openSubscriptionsPage();

        setSubscriptionStateToStorage(responseJson);
      }
      if (apiRequestCallId === this.apiGetUserDetailsCallId) {
        getAndSaveCurrency(responseJson);
        this.getCurrentSubscription(String(this.state.token))
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
