import React from 'react';
import { RegistrationFormView, ButtonComponent, styles as outerStyles } from './styles';
import { Box, Button, InputAdornment, TextField, Typography } from '@material-ui/core';
import { AppHeader } from './AppHeader';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
    imgI14750489301945250HTpe556x7cBoLvN2ZuRRme,
    imgLocationSearch,
} from '../../blocks/landingpage/src/assets';
import MapLocationAdv from './MapLocationAdv';
import { AppFooter } from './AppFooter.web';
import { Places } from '../../blocks/settings1/src/Settings1Controller';
import { getTranslationConfig } from './helpers';

const styles = {
    SafeAreaView: {
        backgroundColor: '#e8f6f4ff',
    },
    inputSearch: {
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        fontSize: '24px',
        color: '#334155',
        padding: '10px 8px',
        display: 'block',
        width: 'auto'
    },
    pageTile: {
        backgroundColor: '#ffffff',
        cursor: 'pointer'
    },
    boldSmall: {
        fontFamily: 'Ubuntu',
        fontSize: '14px',
        fontWeight: 700,
        color: '#000000'
    },
    regularMiddle: {
        fontFamily: 'Ubuntu',
        fontSize: '16px',
        fontWeight: 400,
        color: '#000000'
    },
    tileParagraph: {
        padding: '13px 0'
    },
    dotsOverflow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    venueStatus: {
        color: '#34D399',
        marginRight: '10px'
    },
    backButton: {
        color: '#0F172A',
        cursor: 'pointer'
    }
};

export const mapLocationCore = (context: any) => {
    const { t, dir } = getTranslationConfig();
    const locationAddress = context.state.locationAddress.address;
    const placesByDistance = context.state.places.sort((placeOne: any, placeTwo: any) => placeOne.distance > placeTwo.distance ? 1 : -1)

    return (
        <RegistrationFormView>
            <Box style={styles.SafeAreaView} className='info-page-block'>
                <AppHeader context={this} />
                <Box className='search-location-block' py={6} px='140px' dir={dir}>
                    <ArrowBackIcon
                        style={styles.backButton}
                        onClick={() => context.goToSettings(true)}
                    />
                    <Typography
                        style={{
                            color: '#0F172A',
                            fontFamily: 'Ubuntu',
                            fontSize: '36px',
                            fontWeight: '700',
                            padding: '30px 0'
                        }}
                    >{t['map-location-core-heading']}</Typography>
                    <Box
                        display='flex'
                        justifyContent='space-between'>
                        <Box width='40%'>
                            <TextField
                                id='addressAutocompleteField'
                                placeholder={t['map-location-search-placeholder']}
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img width={24} height={24} src={imgI14750489301945250HTpe556x7cBoLvN2ZuRRme} alt="input-search" />
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                                style={{
                                    ...styles.inputSearch
                                }}
                            />
                            {/* Page tiles */}
                            <Box overflow='auto' mt='20px' height='70vh'>
                                {(placesByDistance as Places).map((place, index) => {
                                    const { name, distance, address, status, statusInfo, coords } = place;
                                    return (
                                        <Box
                                            {...(index && { mt: '20px' })}
                                            p='12px'
                                            style={styles.pageTile}
                                            key={index}
                                            onClick={() => context.setCoords(coords)}
                                        >
                                            <Box display='flex' justifyContent='space-between'>
                                                <Typography
                                                    style={styles.boldSmall}
                                                >
                                                    {name}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontFamily: 'Ubuntu',
                                                        fontSize: '14px',
                                                        fontWeight: 700,
                                                        color: '#94A3B8'
                                                    }}
                                                >
                                                    {distance}
                                                </Typography>
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...styles.regularMiddle,
                                                    ...styles.tileParagraph,
                                                    ...styles.dotsOverflow
                                                }}
                                            >
                                                {address}
                                            </Typography>
                                            <Typography style={styles.boldSmall}>
                                                <span style={styles.venueStatus} >{status}.</span>{statusInfo}
                                            </Typography>
                                        </Box>)
                                }
                                )}
                            </Box>
                            <ButtonComponent mt='20px'>
                                <Button
                                    data-test-id='pin-btn'
                                    variant="contained"
                                    color="primary"
                                    onClick={() => context.goToSettings()}
                                    className="regButton"
                                    style={{
                                        ...outerStyles.formButton,
                                        width: '100%'
                                    }}
                                >
                                    {t['map-location-pin-button']}
                                </Button>
                            </ButtonComponent>
                        </Box>
                        <Box width='55%' height='70vh'>
                            <Typography
                                style={{
                                    ...styles.boldSmall,
                                    marginBottom: '4px'
                                }}>
                                {t['map-location-title']}
                            </Typography>
                            <Box p='12px' maxWidth='80%' borderRadius='30px' bgcolor='#ffffff' whiteSpace='nowrap' mb='14px'>
                                <Box p={1} borderRadius='50%' bgcolor='#FEF3C7' display='inline-block' mr='14px'>
                                    <img src={imgLocationSearch} alt='map marker' />
                                </Box>
                                <Typography
                                    style={{
                                        ...styles.regularMiddle,
                                        ...styles.dotsOverflow,
                                        display: 'inline-block',
                                        width: '90%'
                                    }}>
                                    {locationAddress}
                                </Typography>
                            </Box>
                            <MapLocationAdv
                                setLocationAddress={context.setLocationAddress}
                                setCoords={context.setCoords}
                                setPlaces={context.setPlaces}
                                coords={context.state.coords}
                            />
                        </Box>
                    </Box>
                </Box>
                {/* footer */}
                <AppFooter navigation={context.props.navigation} />
            </Box>
        </RegistrationFormView>
    )
}