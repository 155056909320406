export const img1475048885HTpe556x7cBoLvN2ZuRRme = require('../assets/1475048885HTpe556x7cBoLvN2ZuRRme.svg');
export const imgFacebook = require('../assets/group_face book.png');
export const imgInst = require('../assets/group_insta.png');
export const imgTwitter = require('../assets/group_twitter.png');
export const imgYouTube = require('../assets/group_youtube.png');
export const imgShevronRight = require('../assets/shevron-right.svg');
export const imgStar = require('../assets/star.svg');
export const clodUploadImg = require("../assets/image_cloud_upload.png");
export const imgArrowRightViewMore = require('../assets/arrow-right-view-more.svg');
export const mapMarker = require('../assets/map-marker.png');
export const languageSwitchIcon = require('../assets/language-switch.png');
export const UK_Flag = require('../assets/UK_Flag.svg')
export const Arab_Flag = require('../assets/Arab_Flag.svg')