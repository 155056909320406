import React from "react";
// Customizable Area Start
import { Box, Button, InputLabel, TextField, Modal, Typography, Snackbar, Popover, styled } from '@material-ui/core';
import { location, camera, personOutline, fileIcon, uploadImg, closeIcon, arrowImg, notification, couponImg, editIcon, setAvailablity, setting, logout, hocReporting, my_booking, subscription, reviewSpaNameIcon } from "./assets"
import UserProfileBasicController, {
  initialUserDetails,
  Props
} from "./UserProfileBasicController";
import { AppHeader } from "../../../../packages/components/src/AppHeader.web";
import { AppFooter } from "../../../../packages/components/src/AppFooter.web";
import ClearIcon from '@material-ui/icons/Clear';
import { ModalWrapper, ModalWrapperComponent } from '../../../components/src/ModalWrapperComponent';
import { BreadCrumbs } from '../../../components/src/BreadCrumbs.web';
import { getRtlStyleValue, getTranslationConfig } from '../../../components/src/helpers';
import ConfirmationModal from '../../../components/src/ConfirmationModal';
import { PlanExpiryPrompt } from '../../../components/src/PlanExpiryPrompt';

export const SpaProfileOptionsWrapper = (props: any) => {
  const { transform, isArabic } = getTranslationConfig();

  const { optionItem, isCustomer, handleNavigationToBasicInformation, notifications,
  } = props;
  const isCouponOption = optionItem.name === "Coupon";
  const logoutOptions = ["Logout", "تسجيل الخروج"];
  const isLogoutOption = logoutOptions.includes(optionItem.name);
  const isNotificationOption = ["Notifications", "الاشعارات"].includes(optionItem.name);
  const hasUnreadNotifications = notifications?.filter((notification: any) => !notification.attributes.is_read) || [];

  let itemHandler = handleNavigationToBasicInformation;
  let textColor;
  if (isLogoutOption) {
    itemHandler = props.logout;
    textColor = "#DC2626";
  } else if (isCustomer && isCouponOption) {
    textColor = "#0F172A";
  } else {
    textColor = "#0F172A";
  }

  return <Box data-testId="basic-link" className="spaProfileInfoContainer" onClick={itemHandler}>
    <div style={{ display: "flex" }}>
      <div style={{ background: "#E8F6F4", borderRadius: 8, width: 56, height: 56, position: "relative", }}>
        <img src={optionItem.img} height={24} width="24px" style={{ padding: 16 }} />
        {isNotificationOption && hasUnreadNotifications.length > 0 && (
          <div
           data-testid="notification-badge"
            style={{
              position: "absolute",
              top: 14,
              right: props.getLanguageBasedStyle(isArabic, 'unset', 14),
              left: props.getLanguageBasedStyle(isArabic, 14, 'unset'),
              width: 14,
              height: 14,
              borderRadius: "50%",
              backgroundColor: "red",
              color: "red"
            }}
          />
        )}
      </div>
    </div>
    <Typography
      className="spaOptionText"
      style={{
        color: textColor,
        ...(isArabic && {
          marginLeft: 'auto',
          width: 'auto'
        })
      }}
    >
      {optionItem.name}
    </Typography>
    <div>
      <div style={{ cursor: "pointer" }}>
        <img
          src={arrowImg}
          style={{ ...transform }}
        />
      </div>
    </div>
  </Box>
}
export const MainWrapper = styled(Box)({
  '& .profileNameContainer': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    height: "100px",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center"
  },
  '& .profilename': {
    color: "#FFFFFF",
    fontFamily: '"Ubuntu"',
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '44px',
    letterSpacing: "-0.01em",
    textAlign: "center"
  },
  "& #inputinuserblock": {
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: "pointer",
    borderBottomRightRadius: '100%'
  },
  '& .profileimg': {
    position: 'absolute',
    width: "100%",
    height: "100%",
    borderRadius: "50%"
  },
  '& .breadCrumbText': {
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'left',
    color: "#94A3B8"
  },
  '& .bannerImg': {
    position: 'absolute',
    width: "100%",
    height: "100%",
    objectFit: 'cover',
    borderRadius: 10
  },
  "& .bannerprofileImg": {
    position: 'absolute',
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    opacity: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: "pointer",
  },

  '& .imgContainer': {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    gap: 12,
    alignItems: "center",
    height: "156px",
    width: "156px",
    background: "var(--Primary, #398378)",
    borderRadius: "50%",
    position: "absolute",
    top: "-50px",
    border: "4px solid #fff",
  },
  '& .editIconContainer': {
    cursor: 'pointer',
    position: 'absolute',
    right: '-2px',
    bottom: "6px",
    background: "#E8F6F4",
    padding: "12px",
    borderRadius: "50%"
  },
  '& .imgsuggestionText': {
    fontFamily: '"Ubuntu"',
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "12px",
    textHlign: "center",
    color: "#D9EEEB"
  },
  '& .userNameText': {
    fontFamily: "'Ubuntu'",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left",
  },
  '& .locationSummarytext': {
    fontFamily: "'Ubuntu'",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",

  },
  '& .spaProfileInfoContainer': {
    cursor: "pointer",
    boxShadow: "0px 2px 8px 0px #00000014",
    boxSizing: "border-box",
    marginBottom: "12px",
    display: 'flex',
    background: "#FFFFFF",
    height: "88px",
    padding: "16px 12px 16px 16px",
    gap: "16px",
    borderRadius: "8px 0px 0px 0px",
    opacity: "0px",
    justifyContent: "space-between",
    alignItems: 'center'
  },
  "& .spaOptionText": {
    width: '100%',
    fontFamily: '"Ubuntu"',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    textAlign: 'left',
    color: "#0F172A"
  },
  "& .buttonContainer": {
    height: "44px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "8px",
    background: "#398378",
    fontFamily: "'Ubuntu'",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: 'left',
    color: "#FFFFFF",
    textTransform: "none",
  },
  '& .dailogContainer': {
    width: "793px",
    height: "612px",
    borderRadius: "8px 8px 32px 8px"

  }
})

export const CustomPopover = styled(Popover)({
  "& #inputinuserblock": {
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: "pointer",
    borderBottomRightRadius: '100%'
  },
  '& .tooltip': {
    display: 'flex',
    flexDirection: 'column',
    background: "#fff",
    padding: "0px 2px 0px 2px",
    boxShadow: '0px 2px 4px 0px #00000026',
    height: '96px',
    width: "296px"
  },
  '& .popoverButton': {
    display: 'flex',
    justifyContent: 'flex-start',
    color: "#0F172A",
    alignItems: "center",
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    fonWeight: '400 !important',
    lineHeight: '24px',
    textAlign: 'left',
    background: "#FFFFFF"
  },
  '& .popoverButton:hover': {
    background: "#D9EEEB"
  },


})
export const CustomTypography = styled(Typography)({
  '& .heading': {
    fontSize: '36px',
    fontFamily: 'Ubuntu',
    fontWeight: 700,
    color: '#FFF'
  },
  '& .description': {
    fontSize: '16px',
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    color: '#FFF'
  },
  '& .addLocation': {
    color: '#fff',
    fontFamily: 'Ubuntu',
    fontSize: '14px',
  },
  '& .txtErrorMsg': {
    color: '#DC2626',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Ubuntu',
    paddingTop: '5px'
  },
  '& .title': {
    color: `var(--Basic-Black, #000)`,
    fontFamily: 'Ubuntu',
    fontSize: '24px',
    fontWeight: 700,
  },
  '& .readAndAgree': {
    color: '#0F172A',
    fontFamily: 'Ubuntu',
    fontSize: '12px'
  },
  '& .businessLicense': {
    fontSize: '16px',
    fontWeight: '700',
    fontFamily: 'Ubuntu',
    color: '#334155',
    alignSelf: 'center',
    textAlign: 'center'
  },
  '& .businessLicenseDesc': {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Ubuntu',
    color: '#334155',
    alignSelf: 'center'
  },
  '& .browseFile': {
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'Ubuntu',
    color: '#F59E0B',
    marginLeft: '5px'
  },
  '& .addOther': {
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'Ubuntu',
    color: '#398378',
    paddingLeft: '5px'
  },
  '& .licenseTxt': {
    fontSize: '16px',
    fontFamily: 'Ubuntu',
    fontWeight: '400',
    color: '#0F172A',
  },
  '& .fileSize': {
    fontSize: '14px',
    fontFamily: 'Ubuntu',
    fontWeight: '400',
    color: '#64748B'
  }

})

// Customizable Area End


class SpaUserProfile extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subscriptionRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  subscriptionRef: any
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, dir, isArabic } = getTranslationConfig();

    // Current restricted plan is silver
    const isRestrictedByPlan = this.state.userCurrentSubscription === 1;

    const options = [
      {
        name: t['profile-business-info-text'],
        img: personOutline,
        path: "BasicInformation"
      },
      {
        name: t['profile-my-bookings-title'],
        img: my_booking,
        path: "SpaBooking"
      },
      {
        name: t['profile-availability-text'],
        img: setAvailablity,
        path: "EditAvailabilities",
        callback: this.handleUserAction
      },
      {
        name: t['profile-create-coupons-text'],
        img: couponImg,
        path: "Dashboard",
        restricted: isRestrictedByPlan
      },
      {
        name: t['profile-ad-hoc-text'],
        img: hocReporting,
        path: 'VisualAnalytics',
        restricted: isRestrictedByPlan
      },
      {
        name: t['profile-my-subscription-text'],
        img: subscription,
        path: 'Subscriptionbilling'
      },
      {
        name: t['profile-settings-text'],
        img: setting,
        path: "SpaOwnerSettings",
      },
      {
        name: t['profile-notifications-text'],
        img: notification,
        path: 'NotificationsProfile'
      },
      {
        name: t['profile-logout-text'],
        img: logout,
        path: ''
      }
    ];

    const customerOptions = [
      {
        name: t['profile-basic-info-text'],
        img: personOutline,
        path: "CustomerInformation"
      },
      {
        name: t['profile-my-bookings-title'],
        img: my_booking,
        path: "MyBookings"
      },
      {
        name: t['profile-notifications-text'],
        img: notification,
        path: "NotificationsProfile"
      },
      {
        name: t['profile-settings-text'],
        img: setting,
        path: "CutomerSettings"
      },
      {
        name: t['profile-coupon-text'],
        img: couponImg,
        path: "CustomerCoupons"
      },
    ];

    const { isCustomer } = this.state;
    const {
      profile_data: profileData,
      full_name: SPAVenueName,
      carousal_images: carouselImages,
    } = this.state.userDetails.attributes;

    const profileAttributes = this.getLogicalAndValue(profileData.attributes, initialUserDetails.attributes);

    const menuOptions = this.getLanguageBasedStyle(isCustomer, customerOptions, options);

    const currentLabel = this.getLanguageBasedStyle(isCustomer, t['breadcrumbs-my-profile'], t['breadcrumbs-spa-profile']);

    this.handleSubscription.bind(this, !isCustomer)();

    return (
      <MainWrapper>
        <AppHeader
          context={this}
          showLogin={false}
          forceUpdate={this.forceUpdate.bind(this)}
        />
        <Box
          dir={dir}
          style={{
            backgroundColor: "rgb(232, 246, 244)",
            padding: "50px 150px"
          }}
        >
          <Box style={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)", background: "#F6F0FF", borderRadius: 10 }}>
            <Box style={{ position: "relative", background: "var(--Primary, #398378)", height: "213px", borderRadius: 10 }}>
              {Boolean(carouselImages?.length) && <img
                id="profileBanner"
                src={
                  carouselImages[0].url
                }
                className="bannerImg"
              />}
              <div style={{ position: 'absolute', padding: "20px", zIndex: 10 }}>
                <Box display='flex'>
                  <BreadCrumbs
                    items={[t['breadcrumbs-home'], currentLabel]}
                    targetColor="#FFFFFF"
                    navigationMap={{ 'Home': 'LandingPage' }}
                    navigate={this.props.navigation.navigate}
                  />
                </Box>

              </div>
              <div className="profileNameContainer" style={this.getLabelStyle()}>
                <div style={{ marginTop: "4%", }}>
                  <Typography className="profilename">
                    {currentLabel}
                  </Typography>
                </div>
              </div>
            </Box>
            <Box style={{ background: "#F6F0FF", margin: "0px 100px", paddingBottom: 100 }}>
              <div>
                <div style={{ display: "flex", height: '106px' }}>
                  <div style={{ position: 'relative' }}>
                    <div className="imgContainer">
                      <img
                        id='profileImage'
                        src={this.state.avatarImage}
                        className='profileImg'
                        style={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                        }}
                      />
                      <>
                        <img src={camera} />
                        <div>
                          <Typography className="imgsuggestionText">{t['profile-avatar-placeholder']}<br></br>{t['profile-avatar-description']}</Typography>
                        </div>
                        <input
                          data-testId='profilepic'
                          accept="image/*"
                          id="inputinuserblock"
                          className="imgContainer"
                          onChange={this.handleProfilePicUpload}
                          onClick={() => this.handleUserAction()}
                          type={this.getCorrectInputType()}
                        />
                      </>
                      {this.state.avatarImage && (
                        <div className="editIconContainer">
                          <div style={{ position: "relative" }}>
                            <img
                              data-testId="editIcon"
                              src={editIcon}
                              width={"24px"}
                              height={"24px"}
                              onClick={(event: any) => this.checkRestriction('anchorEl', event.currentTarget)}
                            />
                            <CustomPopover
                              data-test-id='edit-logo-prompt'
                              open={Boolean(this.state.anchorEl)}
                              onClose={() => {
                                this.setState({ anchorEl: null });
                              }}
                              anchorEl={this.state.anchorEl}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: this.getLanguageBasedStyle(isArabic, 'right', 'left'),

                              }}
                              transformOrigin={{
                                vertical: -12,
                                horizontal: this.getLanguageBasedStyle(isArabic, 'right', 'left'),
                              }}
                              PaperProps={{
                                style: { width: '300px', height: 96 }
                              }}
                            >
                              <div className="tooltip" style={{
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: this.getLanguageBasedStyle(isArabic, 'right', 'left'),
                                direction: this.getLanguageBasedStyle(isArabic, 'rtl', 'ltr')
                              }}>
                                <Button className="popoverButton" style={{ height: "48px", padding: '12px 16px 12px 16px', textTransform: 'none', fontWeight: 400 }} onClick={() => { }}>
                                  {isCustomer ? t['profile-edit-photo'] : t['profile-edit-logo']}
                                  <input
                                    data-testId="editLogo"
                                    accept="image/*"
                                    id="inputinuserblock"
                                    className="imgContainer"
                                    onChange={this.handleProfilePicUpload}
                                    type="file"
                                  />
                                </Button>
                                <Button data-testId="removeLogo" className="popoverButton" style={{ height: "48px", padding: '12px 16px 12px 16px', textTransform: 'none', fontWeight: 400 }} onClick={this.handleRemoveProfilePic}>
                                  {isCustomer ? t['profile-remove-photo'] : t['profile-remove-logo']}
                                </Button>
                              </div>
                            </CustomPopover>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      ...getRtlStyleValue(isArabic).infoMargin,
                      width: "55%",
                      display: 'flex',
                      alignItems: "flex-end",
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>
                      <Typography className="userNameText" style={{
                        marginRight: "20px",
                      }}>
                        {SPAVenueName}
                      </Typography>
                      <Box
                        display={'flex'}
                        alignItems={"center"}
                        style={{ gap: 8 }}
                        {...(isArabic && { justifyContent: 'flex-end' })}
                      >
                        <img src={location} height={20} />
                        <Typography className="locationSummarytext"
                        >
                          <Typography>
                            {[profileAttributes.city,', ', profileAttributes.country]}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                    {!isCustomer && (
                      <div>
                        <Button
                          className="buttonContainer"
                          onClick={() => this.checkRestriction('updateProfilePopup', !this.state.updateProfilePopup)}
                          style={{
                            width: "180px",
                            [this.getLanguageBasedStyle(isArabic, 'marginLeft', 'marginRight')]: "-100px",
                          }}
                        >{t['profile-main-update-spa-name-button']}</Button>
                      </div>
                    )}
                  </div>
                </div>
                <Box style={{ marginTop: 60 }}>
                  {menuOptions.map((item: any, index: number) =>
                    <SpaProfileOptionsWrapper
                      optionItem={item}
                      isCustomer={isCustomer}
                      lastIndex={index === menuOptions.length - 1}
                      logout={this.logout}
                      notifications={this.state.notifications}
                      getLanguageBasedStyle={this.getLanguageBasedStyle}
                      getCombinedLogicalValue={this.getCombinedLogicalValue}
                      getAllCombinedLogicalValues={this.getAllCombinedLogicalValues}
                      handleNavigationToBasicInformation={() => {
                        if (item.restricted) {
                          this.setShowRestrictedPrompt(true,
                            {
                              title: '',
                              message: 'Your current subscription plan (Silver) does not grant access to this feature. To unlock this feature and enhance your profile, please upgrade to the Gold .',
                              confirmText: 'Upgrade Now',
                              cancelText: t['profile-settings-logout-cancel-text'],
                              onConfirm: this.gotoSubscriptionPlans,
                            }
                          );
                        } else {
                          item.path && this.handleUserAction(true) && this.handleNavigationToBasicInformation(item.path, item.callback);
                        }
                      }} />
                  )}
                </Box>
              </div>
            </Box>
          </Box>
        </Box>
        <ModalWrapperComponent
          isOpen={this.state.updateProfilePopup}
          modalHeading={t['profile-main-update-spa-name-button']}
          handleClose={this.handleUpdateProfilePopup}
          width={"55.07%"}
        >
          <Box className="modalBOdyContainer" dir={dir}>
            <Box style={{}} className="modalBodyMain">
              <Box >
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <InputLabel className="testLabel" style={{}}>{t['profile-main-new-spa-name-label']}{" "}</InputLabel>
                  <InputLabel className="testStar">{"*"}</InputLabel>
                </Box>
                <TextField
                  fullWidth={true}
                  variant="outlined"
                  className="inputeStyle"
                  data-test-id="txtInputSpaName"
                  placeholder={t['profile-main-spa-name-input-placeholder']}
                  value={this.state.spaName}
                  onChange={this.setSpaName}
                  error={this.state.spaNameError}
                />
                {this.state.spaNameError &&
                  <CustomTypography>
                    <Typography className="txtErrorMsg"
                      style={{
                        backgroundColor: "#FEE2E2",
                        color: "#DC2626",
                        fontFamily: "Ubuntu",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "24px",
                        marginTop: "20px",
                        padding: "12px",
                        ...this.getLanguageBasedStyle(
                          isArabic,
                          {
                            borderRight: "4px solid #DC2626",
                            paddingRight: "12px",
                            borderRadius: "0px 4px 4px 0px"
                          },
                          {
                            borderLeft: "4px solid #DC2626",
                            paddingLeft: "12px",
                            borderRadius: "4px 0px 0px 4px"
                          }
                        )
                      }}
                    >
                      {t['profile-main-spa-name-error-message']}
                    </Typography>
                  </CustomTypography>}
              </Box>
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <InputLabel className="testLabel" style={{ color: "#334155", fontFamily: 'Ubuntu', fontSize: 14, fontWeight: 700 }}>{t['profile-main-spa-license-type-label']}{" "}</InputLabel>
                  <InputLabel className="testStar">{"*"}</InputLabel>
                </Box>
                {this.state.businessLicenseImage.find(this.filterCallBack) ?
                  this.state.businessLicenseImage
                    .filter(this.filterCallBack)
                    .map((file: { type: string, name: string }) =>
                      <Box key={file.name} className="uploadedDocument">
                        <Box className="removeItem">
                          <img src={fileIcon} alt={""} style={{ width: '24px', height: '24px', objectFit: 'cover' }} />
                        </Box>
                        <CustomTypography style={{ width: '100%', paddingLeft: '0.5rem' }}>
                          <Typography className="licenseTxt">{t['profile-main-spa-license-type-label']}</Typography>
                          <Typography className="fileSize">{`(${t['profile-main-spa-license-file-type-label']} - ${file.type?.split("/")[1]})`}</Typography>
                        </CustomTypography>
                        <Box>
                          <ClearIcon
                            data-test-id='clear-icon'
                            style={{
                              fontSize: '32px',
                              color: '#94A3B8',
                              cursor: 'pointer'
                            }}
                            onClick={(index: any) => {
                              const updatedFiles = [...this.state.businessLicenseImage];
                              updatedFiles.splice(index, 1);
                              this.setState({ businessLicenseImage: updatedFiles })
                            }
                            } />
                        </Box>
                      </Box>) :
                  <Box style={{ border: "1px solid #CBD5E1", borderRadius: 8, alignItems: 'center', boxSizing: "border-box", display: 'flex', justifyContent: "space-between", width: "100%", padding: '8px 4px 8px 4px' }}>
                    <Box style={{ position: 'relative', display: 'flex', gap: 12, alignItems: "center" }}>
                      <Box style={{ boxSizing: "border-box", borderRadius: 8, width: 56, height: 56, background: "#E8F6F4", padding: "16px" }}>
                        <img src={uploadImg} />
                      </Box>
                      <input
                        data-testId="Bussiness_licence"
                        accept="image/*"
                        id="inputinuserblock"
                        onChange={this.handleFileChange}
                        type="file"
                      />
                      <Box>
                        <Typography variant="body1" style={{
                          fontFamily: 'Ubuntu', fontWeight: 400,
                          fontSize: 16,
                          lineHeight: '24px', color: "#0F172A"
                        }}>{t['profile-main-spa-license-upload-button']}</Typography>
                        <Typography variant="caption" style={{
                          fontFamily: 'Ubuntu', fontWeight: 400,
                          fontSize: 14,
                          lineHeight: '22px', color: "#64748B"
                        }}>({t['profile-main-spa-license-file-type-label']} - .pdf, .jpg, .jpeg or .png)</Typography>
                      </Box>
                    </Box>
                  </Box>
                }
                {this.state.businessLicenseError &&
                  <CustomTypography>
                    <Typography className="txtErrorMsg"
                      style={{
                        backgroundColor: "#FEE2E2",
                        color: "#DC2626",
                        fontFamily: "Ubuntu",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "24px",
                        marginTop: "20px",
                        padding: "12px",
                        ...this.getLanguageBasedStyle(
                          isArabic,
                          {
                            borderRight: "4px solid #DC2626",
                            paddingRight: "12px",
                            borderRadius: "0px 4px 4px 0px"
                          },
                          {
                            borderLeft: "4px solid #DC2626",
                            paddingLeft: "12px",
                            borderRadius: "4px 0px 0px 4px"
                          }
                        )
                      }}
                    >
                      {t['profile-main-spa-license-label']}
                    </Typography>
                  </CustomTypography>
                }
              </Box>
              <Box width={"70%"}>
                <Box className="webLink">
                  <InputLabel className="testLabel" style={{ color: "#334155", fontFamily: 'Ubuntu', fontSize: 14, fontWeight: 700 }}>{t['profile-main-website-link-label']}{" "}</InputLabel>
                </Box>
                <TextField
                  data-test-id='web-link'
                  fullWidth={true}
                  variant="outlined"
                  className="inputeStyle"
                  placeholder={t['profile-main-website-link-input-placeholder']}
                  value={this.state.webLink}
                  onChange={this.setWebLink}
                />
              </Box>
            </Box>
            <Box style={{
              borderTop: "1px solid #E2E8F0",
              height: "104px",
              borderRadius: "0px 0px 32px 8px",
              padding: "24px",
              display: "flex",
              justifyContent: 'end',
              boxSizing: 'border-box'
            }}>
              <Button
                className="buttonContainer"
                onClick={this.handleSubmitForReview}
              >
                {t['profile-main-spa-submit-for-review-button']}
              </Button>
            </Box>
          </Box>
        </ModalWrapperComponent>
        <Modal
          open={this.state.isSpaNameReviewPopup}
        >
          <ModalWrapper dir={dir} style={{ position: 'relative', width: '36.11%', maxHeight: "700px", height: "auto" }} >
            <Box id="form-dialog-title" className=""
              style={{
                borderBottom: 'none',
                position: "absolute",
                top: 12,
                [this.getLanguageBasedStyle(isArabic, 'left', 'right')]: 12
              }}>
              <Box data-testId="review-close" style={{ cursor: 'pointer' }} onClick={this.handleSpaReviewPopupClose}><img src={closeIcon} /></Box>
            </Box>
            <Box pt={'50px'} pb={'25px'} display='flex' justifyContent={"center"} flexDirection={"column"} style={{ gap: 10 }}>
              <Typography data-testId="spaname_review" style={{ textAlign: 'center', fontFamily: 'Ubuntu', fontSize: 18, lineHeight: "26px", color: "#0F172A" }}>{t['profile-main-spa-name-under-review']}</Typography>
              <Box style={{ display: 'flex', justifyContent: "center" }}>
                <img src={reviewSpaNameIcon} />
              </Box>
            </Box>
          </ModalWrapper>
        </Modal>
        <ConfirmationModal
          openConfirmationModal={this.state.showRestrictedPrompt}
          setOpenConfirmationModal={this.setShowRestrictedPrompt}
          modalConfig={this.state.modalConfig}
        />
        <PlanExpiryPrompt context={this} userAction={this.state.userAction} />
        {/* footer */}
        <AppFooter navigation={this.props.navigation} />
      </MainWrapper >
    );
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

}
// Customizable Area Start

export default SpaUserProfile;
export { SpaUserProfile }
// Customizable Area End
