import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from '../../../framework/src/Utilities';
import { getFormDataFromPayload } from '../../utilities/src/handle-form';
import { planExpiry, publicStripeKey } from '../../../components/src/consts';
import { getTranslationConfig } from "../../../components/src/helpers";
import moment from "moment";

export enum SubscriptionType {
  annually = 'annually',
  monthly = 'monthly'
};

export enum SubscriptionTime {
  year = 'year',
  month = 'month'
};

type PaymentForm = {
  card: string,
  name: string,
  cvv: string,
  expiry: string,
  saveCard: boolean
};

type ParsedDescrList = {
  content: string,
  status: boolean
};

type SubscriptionPlan = {
  id: number;
  name: string,
  description: string,
  price: number,
  convert_currencies:string,
  monthly_price: number,
  symbol: string,
  image_url: string,
  update_plan?: string,
  parse_content: ParsedDescrList[],
  plan_content: string,
  plan_type: SubscriptionType,
  arabic_description:string
};

type Subscription = {
  id: number;
  status: string,
  expiry_date: string,
  plan: SubscriptionPlan
}

export const initSubscription = {
  id: 0,
  status: '',
  expiry_date: '',
  plan: {
    name: '',
    description: '',
    content: '',
    price: 0,
    monthly_price: 0,
    symbol: '',
    image_url: '',
    update_plan: '',
    parse_content: [{
      content: '',
      status: false
    }],
    plan_content: '[]',
    plan_type: SubscriptionType.monthly
  }
}

export const initPaymentForm = {
  card: '',
  name: '',
  cvv: '',
  expiry: '',
  saveCard: false
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  subscriptionButtons?: JSX.Element;
  subsContent?: {
    title: string;
    currancy: string;
    price: number;
    period: SubscriptionTime;
    status?: string,
    expiryDate?: string,
    description: string,
    parsedDescrList: ParsedDescrList[],
    imageUrl: string,
    updatePlan?: string,
    periodLabel: string;
    convert_currencies?:string;
  }
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isRenewMode: boolean;
  showPaymentModal: boolean;
  paymentFormData: PaymentForm;
  paymentModalUpdateMode: boolean;
  currentSubscriptionPlan: Subscription | null;
  subscriptionPlans: SubscriptionPlan[];
  annualMode: boolean;
  congratsData: {
    id: number,
    name: string,
    type: string
  };
  userRoleData: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SubscriptionbillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserCurrentSubscriptionId = '';
  apiGetSubscriptionPlansId = '';
  apiAddCardId = '';
  apiStripeTokenId = '';
  apiCreateSubscriptionId = '';
  currency = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    (async () => {
      this.currency = await getStorageData('currency');
    })();

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isRenewMode: true,
      showPaymentModal: false,
      paymentFormData: initPaymentForm,
      paymentModalUpdateMode: false,
      currentSubscriptionPlan: null,
      subscriptionPlans: [],
      annualMode: false,
      congratsData: {
        id: 0,
        name: '',
        type: ''
      },
      userRoleData: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    window.scrollTo(0, 0);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const navigationPayloadMessage = getName(MessageEnum.NavigationPayLoadMessage);

    if (message.id === navigationPayloadMessage) {
      const { id, name, type, isUpdate } = message.getData(navigationPayloadMessage) || {};
      this.setState({ congratsData: { id, name, type } });
      isUpdate && this.setPaymentUpdateMode(true);
    }

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    const responseData = responseJson?.data;
    const responseError = responseJson?.error

    if (errorReponse || responseError) {
      return alert(`${configJSON.commonErrorText} ${configJSON.commonErrorDescription}`);
    }

    if (apiRequestCallId === this.apiStripeTokenId) {
      // Get fake token for non-prod environments
      this.addCard(this.getEnvToken(responseJson))
    }

    if (apiRequestCallId === this.apiAddCardId) {
      this.createSubscription(responseData.id)
    }

    if (apiRequestCallId === this.apiCreateSubscriptionId) {
      this.gotoSubscriptionCongrats();
      this.setPaymentModal(false);
    }

    if (responseData) {
      if (apiRequestCallId === this.apiGetUserCurrentSubscriptionId) {
        const currentSubscriptionPlan = responseData.find(
          (subscription: { status: string }) => {
            const subscriptionStatus = subscription.status.toLowerCase();

            return subscriptionStatus === 'active' || subscriptionStatus === 'expired'
          }
        );
        this.setState({ currentSubscriptionPlan })
      }
      if (apiRequestCallId === this.apiGetSubscriptionPlansId) {
        this.setState({ subscriptionPlans: responseJson.data })
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async getUserCurrentSubscription() {
    const headers = {
      'Content-Type': configJSON.subscriptionApiContentType,
      'token': await getStorageData('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserCurrentSubscriptionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currentSubscriptionApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subscriptionApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 

  async getSubscriptionPlans() {
    const authToken = await getStorageData('authToken');

    const headers = {
      'Content-Type': configJSON.subscriptionApiContentType,
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSubscriptionPlansId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionPlansApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.subscriptionApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async updateSpaProfilePlan() {
    const authToken = await getStorageData('authToken');
    const accountID = await getStorageData('user_id');

    const formData = getFormDataFromPayload({
      'account[profile][account_id]': accountID,
      'plan_id': this.state.congratsData.id,
    });

    const headers = {
      "token": authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateProfileApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  retrieveStripeToken(cardData: {
    card: string;
    expMonth: string;
    expYear: string;
    cvv: string;
  }) {
    const params = new URLSearchParams();

    const { card, expMonth, expYear, cvv } = cardData;

    params.append('card[number]', card);
    params.append('card[exp_month]', expMonth);
    params.append('card[exp_year]', expYear);
    params.append('card[cvc]', cvv);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.retrieveStripeToken
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        authorization: `Bearer ${publicStripeKey}`
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      params
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    this.apiStripeTokenId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  async addCard(cardToken: string) {
    const authToken = await getStorageData('authToken');

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCardApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type': configJSON.subscriptionApiContentType,
        'token': authToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ card_token: cardToken })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    this.apiAddCardId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async createSubscription(cardId: string) {
    const authToken = await getStorageData('authToken');

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSubscriptionApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type': configJSON.subscriptionApiContentType,
        'token': authToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        card_id: cardId,
        plan_id: this.state.congratsData.id
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    this.apiCreateSubscriptionId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  gotoSettings = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationSettings)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  gotoLogin = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  gotoSubscriptionPayment = (id: number, name?: string, type?: string) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationSubscriptionPayment)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage), { name, type, id, isUpdate: this.state.paymentModalUpdateMode });

    this.send(message);
  }

  gotoSubscriptionPlans = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationSubscriptionPlans)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage), { isUpdate: this.state.paymentModalUpdateMode });

    this.send(message);
  }

  gotoSubscriptionCongrats = () => {
    const { name, type } = this.state.congratsData;

    const message: Message = new Message(
      getName(MessageEnum.NavigationSubscriptionCongrats)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage), { name, type, isUpdate: this.state.paymentModalUpdateMode });

    this.send(message);

    this.updateSpaProfilePlan();
  }

  getRenewButtonStyle = (value?: string | boolean) => Boolean(value) ? {
    background: '#398378',
    color: '#FFFFFF',
  } :
    {
      background: 'var(--Primary, #D9EEEB)',
      color: '#398378',
    }

  openSpaOwnerProfilePage() {
    const msg: Message = new Message(getName(MessageEnum.SpaProfileSuccessMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  setPaymentModal = (value: boolean) => {
    this.setState({ showPaymentModal: value });
    if (!value) {
      this.setPaymentFormData(initPaymentForm);
    }
  }

  setPaymentFormData = (values: PaymentForm) => {
    this.setState({ paymentFormData: values });
  }

  setPaymentUpdateMode = (value: boolean) => this.setState({ paymentModalUpdateMode: value });

  getInputError = (isTouched?: boolean, error?: string) => {
    return Boolean(isTouched && error);
  }

  getModalWindowDetails = () => {
    const { t } = getTranslationConfig();

    return this.state.paymentModalUpdateMode ? {
      title: 'Edit Card Details',
      buttonText: t['subscription-billing-payment-save-card-button']
    } : {
      title: 'Add Card Details',
      buttonText: t['subscription-billing-payment-save-and-pay-button']
    }
  }

  getStatusColor = (status: string) => status === 'Active' ? '#34D399' : '#64748B';

  convertExpiryDate = (expiryDate: string) => {
    return moment(expiryDate).format('DD-MM-YYYY');
  }

  getListBulletColor = (status: boolean) => status ? '#34D399' : '#94A3B8';

  setAnnualMode = (value: boolean) => this.setState({ annualMode: value });

  getEnvToken = (responseJson: Record<string, string>) => process.env.NODE_ENV === 'production' ? responseJson.id : 'tok_visa';

  isSubscription = () => JSON.parse(localStorage.getItem(planExpiry) as string)?.isExisted

  getLogicalAnd = (first: boolean, second: boolean) => first && second;

  getLogicalOr = (first: boolean, second: boolean) => first || second;
  
  getTernaryValue(condition: boolean, firstValue: any, secondValue: any) {
    return condition ? firstValue : secondValue;
  }
  // Customizable Area End
}
