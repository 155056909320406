import React from "react"
// Customizable Area Start
import './SubCategories.css'
import { AppHeader } from '../../../components/src/AppHeader.web'

import { AppFooter } from '../../../components/src/AppFooter.web'
import LandingPageController from "./LandingPageController"
import {
    imgI147504895236797486HTpe556x7cBoLvN2ZuRRme, partTwoLearnMoreImgOne,
    partTwoLearnMoreImgTwo, partTwoLearnMoreImgThree, partTwoLearnMoreImgCheckBox,
    partTwoLearnMoreImgPhone, partThreeLearnBackground, partThreeLearnProfileImage
} from './assets'
import { BreadCrumbs } from "../../../components/src/BreadCrumbs.web"
import { Box, Button, Card, CardContent, Typography, Grid, Pagination } from '@mui/material';
import Loader from "../../../components/src/Loader.web";
import { Home } from "@material-ui/icons"
import MoreVertIcon from '@material-ui/icons/MoreVert';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { List, ListItem, ListItemText } from "@material-ui/core"
import SpaLoginModal from "./components/SpaLoginModal"
import DOMPurify from 'dompurify'
import { getTransContent, getTranslationConfig } from '../../../components/src/helpers';

interface Props {
    navigation: any;
    id: string
}

export default class LearnMore extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        await this.getContentManagementDetails();
    }


    navigationMap = {
        Home: 'LandingPage',
    };

    handleNavigate = (route: string) => {
        this.props.navigation.navigate(route);
    };

    render() {
        const { t, isArabic, dir } = getTranslationConfig();
        const sanitizer = DOMPurify.sanitize;

        const steps = [
            { id: 1, title: t['about-us-learn-more-step-one'], content: t['about-us-learn-more-step-one-description'] },
            { id: 2, title: t['about-us-learn-more-step-two'], content: t['about-us-learn-more-step-two-description'] },
            { id: 3, title: t['about-us-learn-more-step-three'], content: t['about-us-learn-more-step-three-description'] },
            { id: 4, title: t['about-us-learn-more-step-four'], content: t['about-us-learn-more-step-four-description'] },
            { id: 5, title: t['about-us-learn-more-step-five'], content: t['about-us-learn-more-step-five-description'] },
        ];

        const { activeStep } = this.state;
        const activeContent = steps.find((step) => step.id === activeStep);
        // const WhoAndWhatWeAre = this.getWhoWhatWeAre();
        const WhoAndWhatWeAre = getTransContent(this.getWhoWhatWeAre()?.attributes);


        const parser = new DOMParser();
        const description = WhoAndWhatWeAre?.description ?? '';
        const doc = parser.parseFromString(description, 'text/html');
        const liTags = Array.from(doc.querySelectorAll('li')).map((li) => li.textContent);

        const rows = Math.ceil(liTags.length / 2);
        const gridItems = [];

        for (let i = 0; i < rows; i++) {
            const firstIndex = i * 2;
            const secondIndex = firstIndex + 1;

            gridItems.push(
                <Box display="flex" justifyContent="space-between" mt={5} key={i}>
                    <Box display="flex" alignItems="center">
                        <img src={partTwoLearnMoreImgCheckBox} alt="checkbox" />
                        <Typography style={{
                            fontFamily: 'Ubuntu',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            marginLeft: "10px"
                        }}>
                            {liTags[firstIndex] || ''}
                        </Typography>
                    </Box>

                    {liTags[secondIndex] && (
                        <Box display="flex" alignItems="center">
                            <img src={partTwoLearnMoreImgCheckBox} alt="checkbox" />
                            <Typography style={{
                                fontFamily: 'Ubuntu',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                marginLeft: "10px"
                            }}>
                                {liTags[secondIndex]}
                            </Typography>
                        </Box>
                    )}
                </Box>
            );
        }

        return (
            <Box style={{ backgroundColor: "#E8F6F4", height: "full" }}>
                <AppHeader
                    context={this}
                    onClickEvent={() => this.goToLogin()}
                    forceUpdate={this.forceUpdate.bind(this)}
                />
                <Box dir={dir}>
                    <Box className="first-scetion"
                        style={{
                            backgroundImage: `url(${imgI147504895236797486HTpe556x7cBoLvN2ZuRRme})`,
                            backgroundSize: 'cover',
                            width: '100vw',
                            maxHeight: '70px',
                            minHeight: '450px',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '10% 105%',
                            marginBottom: "10px"
                        }}
                    >
                        <Box style={{ paddingTop: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
                            <BreadCrumbs
                                items={[t['breadcrumbs-home'], t['landing-page-about-us-title']]}
                                targetColor="#FFFFFF"
                                navigationMap={this.navigationMap}
                                navigate={this.handleNavigate}
                            />
                        </Box>
                        <Box>
                            <Typography
                                style={{
                                    fontFamily: 'Ubuntu',
                                    fontWeight: 700,
                                    fontSize: '48px',
                                    lineHeight: '56px',
                                    letterSpacing: '-1.5%',
                                    color: "#FFFFFF",
                                    marginTop: "70px",
                                    paddingLeft: "100px",
                                    paddingRight: "100px"
                                }}
                            >
                                {t['landing-page-about-us-title']}
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: 'Ubuntu',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    letterSpacing: '-1.5%',
                                    color: "#FFFFFF",
                                    marginTop: "10px",
                                    paddingLeft: "100px",
                                    paddingRight: "100px",
                                    width: "500px"
                                }}
                            >
                                {t['about-us-learn-more-platform-description']}
                            </Typography>

                        </Box>
                    </Box>
                    <Box className="second-scetion" display="flex" sx={{ minHeight: '600px' }} justifyContent="space-between" mb={6} px={12}>
                        <Box className="left" flex={1}
                        >
                            <Box>
                                <Typography style={{
                                    fontFamily: 'Ubuntu',
                                    fontSize: '36px',
                                    fontWeight: 700,
                                    lineHeight: '44px',
                                    letterSpacing: '-0.01em',
                                    textAlign: this.getLanguageBasedStyle(isArabic, 'right', 'left')
                                }}>
                                    {t['about-us-learn-more-what-we-do']}
                                </Typography>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizer(description),
                                    }}
                                    style={{
                                        fontFamily: 'Ubuntu',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        textAlign: this.getLanguageBasedStyle(isArabic, 'right', 'left')
                                    }}
                                />
                            </Box>
                            <Box>
                                {gridItems}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '8px',
                                    padding: '20px',
                                    gap: '20px',

                                }}
                                mt={5}
                            >
                                <Box display="flex" alignItems="center" >
                                    <img src={partTwoLearnMoreImgPhone} alt="phone" />
                                    <Box mx={2}>
                                        <Typography
                                            style={{
                                                fontFamily: 'Ubuntu',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                lineHeight: '24px',
                                                textAlign: 'left',
                                                color: "#0F172A",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => this.goToContactUsPage()}
                                        >
                                            {t['about-us-learn-more-contact-link']}
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontFamily: 'Ubuntu',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                lineHeight: '24px',
                                                textAlign: 'left',
                                                color: "#64748B"
                                            }}
                                        >
                                            (123) 345-6789
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            className="right"
                            sx={{
                                position: 'relative',
                                width: '100%',
                                height: '500px',
                                flex: 1,
                                marginTop: "55px",
                                marginRight: this.getLanguageBasedStyle(isArabic, "60px", "0px")
                            }}
                        >
                            <Box
                                component="img"
                                src={partTwoLearnMoreImgOne}
                                alt="Image 1"
                                sx={{
                                    position: 'absolute',
                                    top: '0px',
                                    right: '0px',
                                    width: '70%',
                                    height: 'auto',
                                    zIndex: 1,

                                }}
                            />
                            <Box
                                component="img"
                                src={partTwoLearnMoreImgTwo}
                                alt="Image 2"
                                sx={{
                                    position: 'absolute',
                                    top: '90px',
                                    left: '50px',
                                    width: '60%',
                                    height: 'auto',
                                    zIndex: 2,
                                }}
                            />
                            <Box
                                component="img"
                                src={partTwoLearnMoreImgThree}
                                alt="Image 3"
                                sx={{
                                    position: 'absolute',
                                    top: '300px',
                                    left: '200px',
                                    width: '55%',
                                    height: 'auto',
                                    zIndex: 3,

                                }}
                            />

                        </Box>
                    </Box>
                    <Box className="first-scetion"
                        style={{
                            backgroundImage: `url(${partThreeLearnBackground})`,
                            backgroundSize: 'cover',
                            width: '100vw',
                            height: '800px',
                            backgroundRepeat: 'no-repeat',
                            marginBottom: "10px"
                        }}
                    >
                        <Box px={10} pt={5}>
                            <Typography
                                style={{
                                    fontFamily: 'Ubuntu',
                                    fontSize: '36px',
                                    fontWeight: 700,
                                    lineHeight: '44px',
                                    letterSpacing: '-0.01em',
                                    textAlign: this.getLanguageBasedStyle(isArabic, 'right', 'left'),
                                    color: "#FFFFFF"
                                }}
                            >
                                {t['about-us-learn-more-heading']}
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: 'Ubuntu',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                    color: "#FFFFFF",
                                    marginTop: "20px"
                                }}
                            >
                                {t['about-us-learn-more-subheading']}
                            </Typography>
                        </Box>
                        <Box display="flex" px={10} mt={5}>
                            <Box mr={2}>
                                <Box width="30%" mt={2}>
                                    <List >
                                        {steps.map((step) => (
                                            <ListItem
                                                key={step.id}
                                                button
                                                onClick={() => this.handleStepClick(step.id)}
                                                style={{
                                                    width: activeStep === step.id ? '410px' : '400px',
                                                    backgroundColor: activeStep === step.id ? '#E8F6F4' : '#FFFFFF',
                                                    borderBottom: '1px solid #ddd',
                                                    borderLeft: this.getLanguageBasedStyle(isArabic, 'none', activeStep === step.id ? '4px solid #398378' : 'none'),
                                                    borderRight: this.getLanguageBasedStyle(isArabic, activeStep === step.id ? '4px solid #398378' : 'none', 'none'),                                            
                                                    padding: "30px",
                                                    flexDirection: this.getLanguageBasedStyle(isArabic, 'row-reverse', 'row'),
                                                    justifyContent: this.getLanguageBasedStyle(isArabic, 'flex-end', 'flex-start')
                                                }}
                                            >
                                                {
                                                    this.getLanguageBasedStyle(isArabic,
                                                        <React.Fragment key="icon-last">
                                                            <Typography style={{
                                                                fontFamily: 'Ubuntu',
                                                                fontSize: '16px',
                                                                fontWeight: 700,
                                                                lineHeight: '24px',
                                                                textAlign: 'left',
                                                                color: "#0F172A",
                                                            }}>
                                                                {step.title}
                                                            </Typography>
                                                            <KeyboardArrowRightIcon style={{
                                                                fontSize: '24px',
                                                                margin: '0 0 0 8px',
                                                                color: "#398378",
                                                                fontWeight: 'bold'
                                                            }} />
                                                        </React.Fragment>,
                                                        <React.Fragment key="icon-first">
                                                            <KeyboardArrowRightIcon style={{
                                                                fontSize: '24px',
                                                                margin: '0 8px 0 0',
                                                                color: "#398378",
                                                                fontWeight: 'bold'
                                                            }} />
                                                            <Typography style={{
                                                                fontFamily: 'Ubuntu',
                                                                fontSize: '16px',
                                                                fontWeight: 700,
                                                                lineHeight: '24px',
                                                                textAlign: 'right',
                                                                color: "#0F172A",
                                                            }}>
                                                                {step.title}
                                                            </Typography>
                                                        </React.Fragment>                                                        
                                                    )
                                                }
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                                <Box mt={2} textAlign="center">
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#398378',
                                            borderRadius: '8px',
                                            padding: '16px',
                                            width: '350px',
                                            textTransform: 'none',
                                            fontSize: '16px',
                                            fontWeight: 700,
                                            lineHeight: '24px',
                                            fontFamily: 'Ubuntu',
                                            color: '#FFFFFF',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: this.getLanguageBasedStyle(isArabic, 'row-reverse', 'row'),
                                            '&:hover': {
                                                backgroundColor: '#398378',
                                                color: '#FFFFFF',
                                            },
                                        }}
                                        onClick={() => this.setOpenLoginModal(true)}
                                    >
                                        {t['about-us-learn-more-cta']}
                                        <KeyboardArrowRightIcon style={{ fontSize: '16px', fontWeight: 700, marginLeft: '8px' }} />
                                    </Button>
                                </Box>
                            </Box>
                            <Box width="60%" p={3} mx={10} bgcolor="#E8F6F4" >
                                <Box display="flex" gap={2} position="relative">
                                    <Box
                                        component="img"
                                        src={partThreeLearnProfileImage}
                                        alt="Image 3"
                                        sx={{
                                            position: 'absolute',
                                            top: '100px',
                                            left: this.getLanguageBasedStyle(isArabic, '100px', '-100px'),
                                            right: this.getLanguageBasedStyle(isArabic, '-100px', 'unset'),
                                            width: 'auto',
                                            height: 'auto',
                                            zIndex: 3,
                                            boxShadow: 1
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '100px',
                                            left: this.getLanguageBasedStyle(isArabic, 'unset', '150px'),
                                            right: this.getLanguageBasedStyle(isArabic, '150px', 'unset'),
                                            zIndex: 4,
                                            textAlign: this.getLanguageBasedStyle(isArabic, 'right', 'left'),
                                            width: this.getLanguageBasedStyle(isArabic, 'calc(100% - 220px)', 'calc(100% - 220px)'),
                                            marginLeft: this.getLanguageBasedStyle(isArabic, 'unset', '70px'),
                                            marginRight: this.getLanguageBasedStyle(isArabic, '70px', 'unset')
                                        }}
                                    >
                                        <Typography variant="h5" fontWeight="bold">
                                            {activeContent?.title.split('(')[1].slice(0, -1)}
                                        </Typography>
                                        <Typography variant="body1" mt={1}>
                                            {activeContent?.content}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <SpaLoginModal
                    navigation={this.props.navigation}
                    id={''}
                    openLoginModal={this.state.openLoginModal}
                    setOpenLoginModal={this.setOpenLoginModal}
                />
                <AppFooter navigation={this.props.navigation} />
            </Box >
        );

    }
}
